import React from 'react';
import CardMedium from '../Cards/CardMedium/CardMedium';
import plan1 from '../../Assets/Img/Serv/plan1.jpg'
import plan2 from '../../Assets/Img/Serv/plan2.jpg'
import plan3 from '../../Assets/Img/Serv/plan3.jpg'

const PackDriving = () => {
    return (
        <div className='container-fluid'>
            <div className='row d-flex'>
                <div className='col'>
                    <CardMedium
                        img={plan1}
                        title="Plan Perfeccionamiento"
                        hs='6'
                        list={["3 Clases de 2 horas cada una", "Vehículo doble comando", "Circulación en tráfico real", "Estacionamiento"]}
                    />
                </div>

                <div className='col'>
                    <CardMedium
                        img={plan2}
                        title="Plan Control"
                        hs='6'
                        list={["3 clases de 2 horas cada una", "Vehículo doble comando", "Prácticas exhaustivas en tráfico real", "Circulación por avenidas y rotondas", "Prácticas de estacionamiento", "Empleo de videos teóricos en cada clase"]}
                    />
                </div>

                <div className='col'>
                    <CardMedium
                        img={plan3}
                        title="Plan Full"
                        hs='10'
                        list={["5 clases de 2 horas cada una", "Vehículo doble comando", "Reconocimiento de comandos y posturas", "Prácticas exhaustivas en tráfico real", "Horas de manejo para ganar confianza", "Zig-zag, avenidas, rotondas, cruce de puentes, cruce de esquinas, ruta", "Normas de tránsito", "Prácticas de estacionamiento"]}
                    />
                </div>
            </div>
        </div>
    );
    }
    export default PackDriving;