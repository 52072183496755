import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_l09o2tt', 'template_pi9ytzr', form.current, 'd0WpP8berzMOkABGK')
      .then(
        (result) => {
          alert('Se envió correctamente');
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Form
      className="form_style container mb-3"
      ref={form}
      onSubmit={sendEmail}
      action="#"
      data-aos="flip-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="2000"
    >
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nombre y Apellido</Form.Label>
        <Form.Control type="text" name="user_name" placeholder="Escribe tu Nombre y Apellido" required />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" name="user_email" placeholder="name@example.com" required />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Numero de Celular</Form.Label>
          <Form.Control type="number" name="cel" placeholder="Ej.: (381) 153-456785" required />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Pon Aqui Tu Consulta</Form.Label>
        <Form.Control as="textarea" name="message" rows={3} />
      </Form.Group>

      <input className="btn btn-primary mx-5" type="submit" value="Enviar" required />
    </Form>
  );
};
