import React from 'react';
import './WhatsApp.css'

const WhatsApp = (props) => {
    return (
        
        <div>
            <a href='https://wa.me/+5493816706262' class="whatsapp"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>
        </div>
    );
};

export default WhatsApp;