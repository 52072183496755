import './App.css';
import Navbar from './Components/Navbar/Navbar';
import NameLogo from './Components/NameLogo/NameLogo';
import Serv from './Components/ServWork/Serv';
import Separator from './Components/Separator/Separator';
import Parallax from './Components/Parallax/Parallax';
import Footer from './Components/Footer/Footer';
import WhatsApp from './Components/WhatsApp/WhatsApp';
import { ContactUs } from './Components/ContactUs/ContactUs';
import AboutUs from './Components/AboutUs/AboutUs';
import Testimonials from './Components/Testimonials/Testimonials';
import CopyRigth from './Components/CopyRight/CopyRigth';
import PackDriving from './Components/PackDriving/PackDriving';

function App() {

  return (
    <div className=''>
      <Navbar />
      <Parallax />
      <NameLogo />
      <Separator seccion='¡Sobre nosotros!' id='us' />
      <AboutUs />
      <Separator seccion='¡En Que Nos Diferenciamos!' id='' />
      <Serv />
      <Separator seccion='Nuestros Packs' id='pack' />
      <PackDriving />
      <Separator seccion='Algunos de Nuestros Clientes' id='testimonials' />
      <Testimonials />
      <Separator seccion='Enviá Tu Consulta' id='ContactUs' />
      <ContactUs />

      <WhatsApp />
      <Footer />
      <CopyRigth />
    </div>
  );
}

export default App;